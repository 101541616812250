export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>租車流程</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
        <div className='row'>
          {props.data2
            ? props.data2.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
        <div className='col-md-10 col-md-offset-1 section-title'  style={{textAlign: 'left'}}>
          <h2 style={{textAlign: 'center'}}>租車須知</h2>
          <h3 id="-">租借條件：</h3>
          <ol>
            <li>須年滿21歲</li>
            <li>持有中華民國汽車駕照年滿1年以上</li>
          </ol>
          <h3 id="-">預約制</h3>
          <p>公司登記地址為內勤辦公室，故現場並未放有任何車款，如有租車需求請先來電洽詢由專員為您安排預約事項。</p>
          <h3 id="-">天數計算</h3>
          <p>承租1天以24小時計算，取車時間與隔天還車時間相同，預約取車與還車的時間須在營業時間內 (10:00～21:00)。</p>
          <blockquote>
            <p>例：今天上午10點取車，還車為明天的上午10點。</p>
          </blockquote>
          <h3 id="-">超里程限制</h3>
          <ul>
            <li>超跑車輛 單日使用限制為150km</li>
            <li>一般車輛(轎車/休旅車 ) 單日使用限制為200km</li>
            <li>
              若超出限制計價方式為租金除以限制里程=每公里費用
              <blockquote>
                <p>例：車輛單日租金為10000元 單日限制200km，則超出每公里為
                  10000÷200=$50
                </p>
              </blockquote>
            </li>
          </ul>
          <h3 id="-">油差費用</h3>
          <p>還車油量須與出車時登記之油量一致，若油量不足每1/4需收取500元</p>
          <h3 id="-">營業損失</h3>
          <p>若車輛受損，需進廠維修超過1天(含)以上，則視維修天數，再加收營業損失費用。(以日租金定價計算)</p>
          <h3 id="-">應負責任及費用</h3>
          <ol>
            <li>車輛租用期間發生違規罰單或停車費，該費用必須由承租人負擔。</li>
            <li>若行車速度超過規定之最高時速60公里以上競速飆車得罰款3萬元至9萬元整，並得吊扣車牌3個月，上述違規行為除罰單金額由承租人負擔外，另就車牌吊扣導致至營業損失部分，亦由承租人負擔。</li>
            <li>承租人因酗酒駕車、犯罪行為合法拘捕、吸食禁藥、故意行為、轉介於無照駕駛人或違約行為導致車輛損壞，需由承租人負全部賠償責任，不受車損最高自負額限制。</li>
          </ol>
          <h3 id="-">緊急事件處理</h3>
          <p>車輛若發生任何意外事故、失竊或其他肇事時，為維護您的權益，請您立即撥打0902-025-920與我們聯繫，專員會協助您處理後續事宜。</p>
          <h3 id="-">匯款資訊</h3>
          <ol style={{textAlign: 'center'}}>
            <li>戶名：最高租賃有限公司</li>
            <li>中國信託 882 （中崙分行）093-54028603-2</li>
            <li>統一編號：83190147</li>
            <li>匯款後請告知帳號後五碼，金額為總租金50％</li>
            <li>於預訂取車日前24小時取消，將扣留100％訂金</li>
          </ol>
        </div>
        
      </div>
    </div>
  )
}
