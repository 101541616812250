export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>聯絡我們</h2>
                <p>
                  地圖
                </p>
                <iframe title="My" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.467255353715!2d121.54398291500675!3d25.05214748396392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abe7866ea3e5%3A0x2e2e804f00b6cee4!2zMTA15Y-w5YyX5biC5p2-5bGx5Y2A5Y2X5Lqs5p2x6Lev5LiJ5q61Mjg36Jmf!5e0!3m2!1szh-TW!2stw!4v1636270562644!5m2!1szh-TW!2stw" style={{width: 100+'%', height:480+'px'}} loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>聯絡資訊</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> 地址
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> 電話
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <a href={props.data ? props.data.line : 'loading'} target="_blank" rel="noreferrer">
                <p>
                  <span>
                    <i className='fa fa-whatsapp'></i> Line
                  </span>{' '}@goatrentalservice
                </p>     
              </a>
            </div>
            <div className='contact-item'>
              <a href={props.data ? props.data.facebook : 'loading'} target="_blank" rel="noreferrer">
                <p>
                  <span>
                    <i className='fa fa-facebook'></i> Facebook
                  </span>{' '}@goatrentalservice
                </p>     
              </a>
            </div>
            <div className='contact-item'>
              <a href={props.data ? props.data.instagram : '/'} target="_blank" rel="noreferrer">
                <p>
                  <span>
                    <i className='fa fa-instagram'></i> Instagram
                  </span>{' '} @goat_rental
                </p>
              </a>            
            </div>
          </div>
          <div className='social'>
            <ul>
              <li>
                <a href={props.data ? props.data.line : '/'} target="_blank" rel="noreferrer">
                  <i className='fa fa-whatsapp'></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.facebook : '/'} target="_blank" rel="noreferrer">
                  <i className='fa fa-facebook'></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.instagram : '/'} target="_blank" rel="noreferrer">
                  <i className='fa fa-instagram'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
