
export const Gallery2 = (props) => {
  return (
    <div id='portfolio2' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>形象影片</h2>
          <a href="https://www.facebook.com/goatrentalservice/videos/"  target="_blank" rel="noreferrer">
            <p>
              更多
            </p>
          </a>
        </div>
        <div className='row'>
          <div className="slider">
            <video controls="controls">
              <source src="img/portfolio/headervid.mp4" type="video/mp4"/>
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}
