export const Header = (props) => {
  return (
    <header id='header'>
      <video autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto" >
        <source src="img/portfolio/headervid.mp4" type="video/mp4"/>
      </video>
      <div className="content">
        <h1>GOAT INC. CAR RENTAL SERVICE</h1>
        <p>The Best Rental Service</p>
      </div>   
    </header>
  )
}
