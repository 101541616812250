
export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>車款</h2>
          <p>
            GOAT INC. 提供各式超跑歡迎來電詢問
          </p>
        </div>
        <div className='row'>
          <div className="slider">
            <ul id="lightSlider">
                {props.data
                  ? props.data.map((d, i) => (
                    <li data-thumb={d.smallImage}>
                        <a href="https://www.facebook.com/goatrentalservice/shop/" target="_blank" rel="noreferrer">
                          <img alt="car" src={d.largeImage} />
                        </a>
                    </li>
                  ))
                  : 'Loading...'}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
